<template>
  <div class="login">
    <van-loading v-show="loading" size="60px" type="spinner" color="#1989fa"></van-loading>
    <img src="@/assets/images/logo.png" />
    <div class="container">
      <van-steps :active="active" active-color="#3aaffd">
        <van-step v-for="item in stepsList" :key="item">
          {{ item }}
          <template v-slot:active-icon>
            <img src="@/assets/images/step.png" />
          </template>
          <template v-slot:inactive-icon>
            <img src="@/assets/images/stepIn.png" />
          </template>
          <template #finish-icon>
            <img src="@/assets/images/step.png" />
          </template>
        </van-step>
      </van-steps>
      <van-form class="fieldForm1" v-show="active === '0'">
        <van-field v-model="tel" type="tel" name="tel" placeholder="请输入您的手机号" autocomplete="off">
          <template #left-icon>
            <img src="@/assets/images/phoneIcon.png" />
          </template>
        </van-field>
        <van-field v-model="code" name="code" placeholder="输入验证码" type="digit" autocomplete="off">
          <template #button>
            <van-button plain hairline type="info" color="#4493fa" @click="onButtonClick" :disabled="codeState === 2">
              {{ codeState === 1 ? '获取验证码' : codeState === 2 ? time + '秒后重试' : '重新发送' }}
            </van-button>
          </template>
          <template #left-icon>
            <img src="@/assets/images/codeIcon.png" />
          </template>
        </van-field>
        <van-checkbox label-disabled v-model="checked" shape="square"
          >已阅读同意《<span @click="viewAgreement">今医办用户服务协议</span>》</van-checkbox
        >
        <div style="padding: 68px 0px 550px">
          <van-button size="large" class="loginBtn" color="linear-gradient(to right, #01c2ce, #2f88fe)" @click="onLoginClick"
            >登录</van-button
          >
        </div>
      </van-form>
    </div>
      <!-- 协议内容弹窗 -->
      <van-dialog v-model="agreementShow" title="用户协议" width="80%" class="agreement" @confirm="agreementShow = false">
        <div>今医办用户协议</br>
          一、定义及协议范围</br>
          1.1 “今医办平台”是今医办公司提供的医疗服务平台，包括App、网站、小程序等。</br>
          1.2 本协议规定了用户使用今医办平台的权利和义务。</br>
          </br>
          二、账号管理</br>
          2.1 用户注册账号时应提供真实、准确、有效的个人信息。</br>
          2.2 用户应妥善保管账号和密码，对账号行为负责。</br>
          </br>
          三、今医办平台服务内容与费用</br>
          3.1 今医办平台提供智能医疗资源匹配、数据分析、医疗咨询等服务。</br>
          3.2 服务费用将根据服务类型和用户选择进行明示，今医办保留调整费用的权利。</br>
          </br>
          四、权利与许可</br>
          4.1 今医办平台拥有提供服务所需的知识产权，包括但不限于软件、内容、商标等。</br>
          4.2 用户有权在遵守法律法规和本协议的前提下使用今医办平台服务。</br>
          </br>
          五、法律责任</br>
          5.1 用户应合法使用今医办平台，不得发布违法信息或侵犯他人权益。</br>
          5.2 违反协议或法律法规的用户将承担相应的法律责任。</br>
          </br>
          六、责任限制与免责事由</br>
          6.1 今医办对第三方服务提供商的行为不承担责任。</br>
          6.2 今医办不对不可抗力导致的服务中断或损失承担责任。</br>
          </br>
          七、协议变更与终止</br>
          7.1 今医办有权根据法律法规或服务需要对协议内容进行变更。</br>
          7.2 用户如不同意变更内容，有权停止使用服务。</br>
          </br>
          八、法律适用与管辖</br>
          8.1 本协议适用中华人民共和国法律。</br>
          8.2 争议解决地为今医办公司注册地的人民法院。</br>
          </br>
          九、其他</br>
          9.1 用户对本协议有疑问或建议，可通过今医办平台提供的渠道反馈。</br>
          </br>
          用户隐私政策
          </br>
          今医办用户隐私政策
          </br>
          一、我们如何收集和使用您的个人信息</br>
          1.1 我们收集的信息包括但不限于用户的基本信息、医疗服务使用记录等。</br>
          1.2 我们收集信息的目的是提供个性化的医疗服务和改进服务质量。</br>
          </br>
          二、我们如何委托处理、共享、转让、公开披露您的个人信息</br>
          2.1 我们可能委托第三方处理个人信息，但要求第三方同样保护用户隐私。</br>
          2.2 我们不会共享、转让或公开披露您的个人信息，除非法律法规要求或得到您的明确同意。</br>
          </br>
          三、我们如何存储和保护您的个人信息</br>
          3.1 您的个人信息将被加密存储，并定期进行安全审计。</br>
          3.2 我们采取技术措施和管理措施，防止信息泄露、滥用或丢失。</br>
          </br>
          四、您如何管理您的个人信息</br>
          4.1 用户可以通过今医办平台的功能自主管理个人信息。</br>
          4.2 用户有权访问、更正、删除个人信息，或撤回同意。</br>
          </br>
          五、您如何注销您的账号</br>
          5.1 用户可按照今医办平台的指引申请注销账号。</br>
          5.2 注销账号后，我们将依法处理您的个人信息。</br>
          </br>
          六、有关第三方提供产品和/或服务的特别说明</br>
          6.1 第三方服务提供商的隐私政策可能与今医办不同，用户需自行了解并同意。</br>
          </br>
          七、我们如何使用Cookie和其他同类技术</br>
          7.1 我们使用cookies来提供个性化服务和改进用户体验。</br>
          7.2 用户可以通过浏览器设置管理cookies。</br>
          </br>
          八、未成年人保护</br>
          8.1 我们特别关注未成年人的隐私保护，要求监护人同意未成年人提供个人信息。</br>
          8.2 我们为未成年人提供额外的隐私保护措施。</br>
          </br>
          九、我们如何更新隐私政策</br>
          9.1 我们会根据法律法规变化或服务调整更新隐私政策。</br>
          9.2 更新后的隐私政策将通过今医办平台通知用户。</br>
          </br>
          十、如何联系我们</br>
          10.1 用户可以通过今医办平台提供的联系方式提出疑问或反馈。</br>
          10.2 我们将在收到用户反馈后尽快响应。
        </div>
      </van-dialog>

  </div>
</template>

<script>
  import { getPhoneCode, verifyPhoneCode, addEquity, getServiceQr, loginJump } from '@/api';
  export default {
    name: 'loginPage',
    data() {
      return {
        active: '0',
        stepsList: ['手机验证', '信息激活', '激活完成'],
        tel: '',
        code: '',
        codeState: 1,
        time: 60,
        intervalId: null,
        checked: false,
        form2: {
          name: '',
          idCard: '',
          gender: '0',
          activationCode: '',
        },
        imgBase: '',
        token: '',
        toUp: true,
        loading: false,
        agreementShow: false,
      };
    },
    created() {
      const token = localStorage.getItem('token');
      if (token) {
        this.token = token;
        this.active = '1';
        this.toUp = false;
      }
    },
    methods: {
      // 校验手机号
      checkTel() {
        let reg = /^1[3-9]\d{9}$/; //手机号
        let res = reg.test(this.tel);
        if (!res) {
          this.$dialog.alert({
            message: '请输入正确的手机号',
          });
        }
        return res;
      },
      /* 获取验证码 */
      onButtonClick() {
        if (!this.checkTel()) return;
        this.intervalId = setInterval(() => {
          if (this.time > 1) {
            if (this.codeState !== 2) this.codeState = 2;
            this.time = this.time - 1;
          } else {
            clearInterval(this.intervalId);
            this.codeState = 3;
            this.time = 60;
          }
        }, 1000);
        getPhoneCode({ phone: this.tel })
          .then((res) => {
            if (res.status === 1) {
              console.log(res);
            } else {
              throw new Error(res.message);
            }
          })
          .catch((error) => {
            this.$dialog.alert({
              message: error.message,
            });
            clearInterval(this.intervalId);
            this.codeState = 3;
            this.time = 60;
          });
      },
      //查看协议
      viewAgreement() {
        this.agreementShow = true;
      },
      onLoginClick() {
        if (!this.checkTel()) return;
        if (!this.code.trim() || !this.checked) {
          const msg = !this.code.trim() ? '验证码不能为空' : '请阅读并勾选服务协议';
          this.$dialog.alert({
            message: msg,
          });
          return;
        }
        verifyPhoneCode({ phone: this.tel, code: this.code })
          .then((res) => {
            if (res.status === 1) {
              let token = res.data?.token || '';
              this.token = token;
              localStorage.setItem('token', token);
              // 关闭倒计时
              if (this.intervalId) {
                clearInterval(this.intervalId);
                this.codeState = 1;
                this.time = 60;
              }
              loginJump({ token })
              .then((res) => {
                console.log(res)
                if(res.status === 1 && res.data.isJump === 1) {
                  // 去首页
                  this.$router.push({ name: 'homePage' });
                } else {
                  // 去激活页面
                  this.$router.push({ name: 'activationPage' });
                }
              })

            } else {
              throw new Error(res.message);
            }
          })
          .catch((error) => {
            this.$dialog.alert({
              message: error.message,
            });
          });
      },
      upStep() {
        if (this.toUp) {
          this.active = '0';
        }
      },
      // 校验身份证
      validIDRe(idVal) {
        let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        let _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
        if (_IDRe18.test(idVal) || _IDre15.test(idVal)) {
          return true;
        } else {
          return false;
        }
      },
    },
  };
</script>
<style scoped lang="scss">
  .login {
    position: relative;

    & > img {
      width: 100%;
      height: 523px;
    }

    .van-loading {
      background: rgba(52, 52, 54, 0.8);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 9999;
      left: 0;
      display: flex;
      justify-content: center;
      padding-top: 530px;
    }

    .container {
      ::v-deep .van-steps {
        padding: 0 137px;
        height: 100px;

        .van-step:nth-child(1) .van-step__title {
          margin-left: -10px;
        }

        .van-step:nth-child(3) .van-step__title {
          margin-right: -10px;
        }

        .van-step:nth-child(2) .van-step__circle-container {
          left: -37px;
        }

        img {
          width: 60px;
          height: 60px;
        }

        .van-step__title {
          font-size: 20px;
        }

        .van-step__line {
          background: #e3e3e3;
          top: 50px;
        }

        .van-step__circle-container {
          top: 52px;
        }
      }

      ::v-deep .van-form .van-cell {
        font-size: 16px;

        .van-field__control {
          color: #a3a3a3;
          font-size: 26px;
        }
      }

      //第一步
      .fieldForm1 {
        padding: 77px 77px 0;

        ::v-deep .van-cell {
          border: 1px solid #ddd;
          border-radius: 60px;
          height: 70px !important;
          margin-bottom: 40px;
          box-shadow: 0px 1px 13px 0 rgba(111, 143, 195, 0.5);

          .van-field__control {
            height: 70px;
            padding: 0px 10px;
          }

          .van-field__body {
            height: 100%;
          }

          .van-field__left-icon {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
          }

          img {
            width: 28px;
            height: 28px;
          }
        }

        & > div:nth-child(2) {
          padding-top: 0;
          padding-bottom: 0;
        }

        .van-field__button button {
          width: 198px;
          height: 65px;
          font-size: 23px;
          margin-right: -15px;
        }

        .van-button--hairline::after {
          border-radius: 70px;
          border-width: 5px;
        }
        .van-checkbox {
          font-size: 16px;
          .van-checkbox__label span {
            color: #4493fa;
          }
        }
      }
      .van-button {
        border-radius: 50px;
        height: 70px;
        width: 100%;
        font-size: 30px;
        box-shadow: 0px 1px 13px 0 rgba(111, 143, 195, 0.5);
      }

      //第二步
      .fieldForm2 {
        padding: 70px 74px 0 64px;
        ::v-deep .van-icon:before,
        ::v-deep .van-cell__title {
          font-size: 25px;
          line-height: 100px;
          margin-right: 20px;
        }
        ::v-deep .van-cell {
          height: 89px !important;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 6px;

          .van-field__control {
            font-size: 25px;
          }

          .van-cell__title {
            color: #a5a5a5;
            width: 120px;
            line-height: 70px !important;
          }

          .van-cell__value {
            margin-left: -40px !important;
            margin-right: 10px;
            padding: 0px 40px;
            border-radius: 40px;
            line-height: 80px !important;
            box-shadow: 0px 1px 13px 0 rgba(111, 143, 195, 0.5);
          }

          .van-field__body .van-field__control {
            height: 69px !important;
          }

          &.form2_input_none {
            .van-cell__value {
              border: none;
              box-shadow: none;
              padding: 0px !important;
            }
          }
        }

        ::v-deep .van-radio-group {
          display: flex;
          height: 89px;
          width: 100%;

          & > div {
            width: 50%;
            box-sizing: border-box;
            margin-right: 0;
          }

          .van-radio__icon {
            display: flex;
            height: 89px;
            width: 100%;

            & > div {
              line-height: 69px;
              background: #fff;
              border-radius: 100px;
              text-align: center;
              box-shadow: 0px 0px 12px 0 #ccc;
              margin: 10px;
              border: transparent 2px solid;
              font-size: 25px;
              font-weight: 500;
              box-shadow: 0px 1px 13px 0 rgba(111, 143, 195, 0.5);
              width: 100%;
              color: #a3a3a3;

              &.action {
                border: #4493fa 2px solid;
                color: #4493fa;
              }
            }
          }
        }

        .btn-box {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 64px 0 400px 0;

          .van-button {
            width: 47%;
          }
        }
      }

      //第三步
      .success-box {
        text-align: center;
        padding: 20px 0 170px 0;

        & > img {
          width: 408px;
          height: 408px;
          margin: 10px 0 36px;
        }

        & > div {
          img {
            width: 307px;
            height: 307px;
            pointer-events: auto;
          }
        }

        & > span {
          display: block;
          color: #a5a5a5;
          font-size: 28px;
          margin-top: 24px;
        }
        p {
          position: fixed;
          bottom: 70px;
          width: 100%;
          text-align: center;
          button {
            width: 80%;
          }
        }
      }
    }
  }
</style>
<style>
  .van-dialog.agreement .van-dialog__header {
    font-size: 24px;
    font-weight: bold;
  }

  .van-dialog.agreement .van-dialog__content {
    height: 50vh;
    overflow: auto;
    padding: 10px 20px;
    color: #716b6b;
  }
  .van-dialog.agreement .van-dialog__content div {
    font-size: 16px !important;
    /* text-indent: 2em; */
  }
</style>
