<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {},
};
</script>

<style>
body {
  font-family: "Source Han Serif", serif !important;
  font-weight: bold;
}
img {
  pointer-events: none;
}
.van-toast {
  min-width: 40vw !important;
}

.van-toast__text {
  font-size: 25px;
}

.van-dialog__message {
  font-size: 25px !important;
  font-weight: 500;
  font-family: -apple-system;
}

.van-dialog {
  min-width: 60vw !important;
}

.van-dialog__content {
  border-bottom: 1px solid #ccc !important;
}

.van-dialog__footer .van-button {
  color: #3982f1 !important;
  font-size: 25px !important;
  font-family: -apple-system;
  height: 60px;

}
</style>
