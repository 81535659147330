import VueRouter from "vue-router";
import Home from '../views/Home'
import Login from '../views/Login'
import Activation from '../views/Activation'

// 创建并暴露一个路由器
export default new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name:'homePage',
      component: Home,
    },
    {
      path: '/login',
      name:'loginPage',
      component: Login,
    },
    {
      path: '/activation',
      name:'activationPage',
      component: Activation,
    }
  ]
})