<template>
    <div class="login">
      <van-loading v-show="loading" size="60px" type="spinner" color="#1989fa"></van-loading>
      <img src="@/assets/images/logo.png" />
      <div class="container">
        <van-steps :active="active" active-color="#3aaffd">
          <van-step v-for="item in stepsList" :key="item">
            {{ item }}
            <template v-slot:active-icon>
              <img src="@/assets/images/step.png" />
            </template>
            <template v-slot:inactive-icon>
              <img src="@/assets/images/stepIn.png" />
            </template>
            <template #finish-icon>
              <img src="@/assets/images/step.png" />
            </template>
          </van-step>
        </van-steps>

        <van-form class="fieldForm2" v-show="active === '1'">
          <van-field v-model="form2.name" name="name" :label="`姓  名`" placeholder="请输入姓名" autocomplete="off" />
          <van-field v-model="form2.idCard" name="idCard" label="身份证" placeholder="请输入身份证" autocomplete="off" />
          <van-field class="form2_input_none" label="性 别" autocomplete="off">
            <template #input>
              <van-radio-group v-model="form2.gender" direction="horizontal">
                <van-radio name="0"
                  ><template #icon="props">
                    <div :class="{ action: props.checked }">男</div>
                  </template>
                </van-radio>
                <van-radio name="1"
                  ><template #icon="props">
                    <div :class="{ action: props.checked }">女</div>
                  </template></van-radio
                >
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-model="form2.activationCode" name="activationCode" label="激活码" placeholder="请输入激活码" autocomplete="off" />
          <div class="btn-box">
            <van-button size="large" :color="toUp ? 'linear-gradient(to right, #01c2ce, #2f88fe)' : '#ccc'" @click="upStep"
              >上一步</van-button
            >
            <van-button size="large" color="linear-gradient(to right, #01c2ce, #2f88fe)" @click="activationHandler">激活</van-button>
          </div>
        </van-form>
        <div v-show="active === '2'" class="success-box">
        <img src="@/assets/images/success.png" />
        <div>
          <img :src="imgBase" alt="图片有问题" />
        </div>
        <span>长按二维码</span>
        <p>
          <van-button size="large" color="linear-gradient(to right, #ff6b05, #ff4f5a)" @click="toHealthCard">使用健康卡</van-button>
        </p>
      </div>
      </div>
    </div>
  </template>
  
  <script>
    import { getPhoneCode, verifyPhoneCode, addEquity, getServiceQr, loginJump } from '@/api';
    export default {
      name: 'loginPage',
      data() {
        return {
          active: '0',
          stepsList: ['手机验证', '信息激活', '激活完成'],
          tel: '',
          code: '',
          codeState: 1,
          time: 60,
          intervalId: null,
          checked: false,
          form2: {
            name: '',
            idCard: '',
            gender: '0',
            activationCode: '',
          },
          imgBase: '',
          token: '',
          toUp: true,
          loading: false,
          agreementShow: false,
        };
      },
      created() {
        const token = localStorage.getItem('token');
        if (token?.length > 0) {
          loginJump({ token }).then((res) => {
            if (res.status === 0) {
              // 回到首页
              this.$router.push({ name: 'homePage' });
            } else {
              // 放在这里,防止token过期也把页面显示了
              this.token = token;
              this.active = '1';
              this.toUp = false;
            }
          })
        } else {
          // 回到首页
          this.$router.push({ name: 'homePage' });
        }
      },
      methods: {
        upStep() {
          // 回到首页
          this.$router.push({ name: 'homePage' });
          // if (this.toUp) {
          //   this.active = '0';
          // }
        },
        // 校验身份证
        validIDRe(idVal) {
          let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
          let _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
          if (_IDRe18.test(idVal) || _IDre15.test(idVal)) {
            return true;
          } else {
            return false;
          }
        },
        activationHandler() {
          const { name, idCard, activationCode } = this.form2;
          let msg = '';
          if (!name.trim()) {
            msg = '请输入姓名';
          } else if (name.length > 5) {
            msg = '姓名长度不可超过5个字符';
          } else if (!this.validIDRe(idCard)) {
            msg = '身份证号格式不正确';
          } else if (!activationCode.trim()) {
            msg = '激活码不能为空';
          }
          if (msg) {
            this.$dialog.alert({
              message: msg,
            });
            return;
          }
          let that = this;
          addEquity({ ...this.form2, token: this.token })
            .then((res) => {
              if (res.status === 1) {
                this.generateQRCode();
                this.active = '2';
                localStorage.setItem('isActivation', 'true');
              } else {
                throw new Error(res.message);
              }
            })
            .catch((error) => {
              that.$dialog.alert({
                message: error.message,
                beforeClose(action, done) {
                  if (error.message == '请登录后再操作') {
                    localStorage.removeItem('token');
                    that.form2 = {
                      name: '',
                      idCard: '',
                      gender: '0',
                      activationCode: '',
                    };
                    that.active = '0';
                  }
                  done();
                },
              });
            });
        },
        generateQRCode() {
          this.loading = true;
          getServiceQr()
            .then((res) => {
              if (res.status === 1) {
                this.imgBase = res?.data?.url || '';
                this.loading = false;
              } else {
                throw new Error(res.message);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$dialog.alert({
                message: error.message,
              });
            });
        },
        toHealthCard() {
          loginJump({  token: this.token })
            .then((res) => {
              if (res.status === 1) {
                const { isJump, jumpUrl } = res.data;
                if (isJump === 0) {
                  this.form2 = {
                    name: '',
                    idCard: '',
                    gender: '0',
                    activationCode: '',
                  };
                  this.active = '1';
                  this.toUp = false;
                } else if (isJump === 1) {
                  window.location.href = jumpUrl;
                } else {
                  throw new Error(res.message);
                }
              } else {
                this.tel = '';
                this.code = '';
                localStorage.removeItem('token');
                this.active = '0';
              }
            })
            .catch((error) => { 
              this.$dialog.alert({
                message: error.message,
              });
            })
        },
      },
    };
  </script>
  <style scoped lang="scss">
    .login {
      position: relative;
  
      & > img {
        width: 100%;
        height: 523px;
      }
  
      .van-loading {
        background: rgba(52, 52, 54, 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 9999;
        left: 0;
        display: flex;
        justify-content: center;
        padding-top: 530px;
      }
  
      .container {
        ::v-deep .van-steps {
          padding: 0 137px;
          height: 100px;
  
          .van-step:nth-child(1) .van-step__title {
            margin-left: -10px;
          }
  
          .van-step:nth-child(3) .van-step__title {
            margin-right: -10px;
          }
  
          .van-step:nth-child(2) .van-step__circle-container {
            left: -37px;
          }
  
          img {
            width: 60px;
            height: 60px;
          }
  
          .van-step__title {
            font-size: 20px;
          }
  
          .van-step__line {
            background: #e3e3e3;
            top: 50px;
          }
  
          .van-step__circle-container {
            top: 52px;
          }
        }
  
        ::v-deep .van-form .van-cell {
          font-size: 16px;
  
          .van-field__control {
            color: #a3a3a3;
            font-size: 26px;
          }
        }
  
        //第一步
        .fieldForm1 {
          padding: 77px 77px 0;
  
          ::v-deep .van-cell {
            border: 1px solid #ddd;
            border-radius: 60px;
            height: 70px !important;
            margin-bottom: 40px;
            box-shadow: 0px 1px 13px 0 rgba(111, 143, 195, 0.5);
  
            .van-field__control {
              height: 70px;
              padding: 0px 10px;
            }
  
            .van-field__body {
              height: 100%;
            }
  
            .van-field__left-icon {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
            }
  
            img {
              width: 28px;
              height: 28px;
            }
          }
  
          & > div:nth-child(2) {
            padding-top: 0;
            padding-bottom: 0;
          }
  
          .van-field__button button {
            width: 198px;
            height: 65px;
            font-size: 23px;
            margin-right: -15px;
          }
  
          .van-button--hairline::after {
            border-radius: 70px;
            border-width: 5px;
          }
          .van-checkbox {
            font-size: 16px;
            .van-checkbox__label span {
              color: #4493fa;
            }
          }
        }
        .van-button {
          border-radius: 50px;
          height: 70px;
          width: 100%;
          font-size: 30px;
          box-shadow: 0px 1px 13px 0 rgba(111, 143, 195, 0.5);
        }
  
        //第二步
        .fieldForm2 {
          padding: 70px 74px 0 64px;
          ::v-deep .van-icon:before,
          ::v-deep .van-cell__title {
            font-size: 25px;
            line-height: 100px;
            margin-right: 20px;
          }
          ::v-deep .van-cell {
            height: 89px !important;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 6px;
  
            .van-field__control {
              font-size: 25px;
            }
  
            .van-cell__title {
              color: #a5a5a5;
              width: 120px;
              line-height: 70px !important;
            }
  
            .van-cell__value {
              margin-left: -40px !important;
              margin-right: 10px;
              padding: 0px 40px;
              border-radius: 40px;
              line-height: 80px !important;
              box-shadow: 0px 1px 13px 0 rgba(111, 143, 195, 0.5);
            }
  
            .van-field__body .van-field__control {
              height: 69px !important;
            }
  
            &.form2_input_none {
              .van-cell__value {
                border: none;
                box-shadow: none;
                padding: 0px !important;
              }
            }
          }
  
          ::v-deep .van-radio-group {
            display: flex;
            height: 89px;
            width: 100%;
  
            & > div {
              width: 50%;
              box-sizing: border-box;
              margin-right: 0;
            }
  
            .van-radio__icon {
              display: flex;
              height: 89px;
              width: 100%;
  
              & > div {
                line-height: 69px;
                background: #fff;
                border-radius: 100px;
                text-align: center;
                box-shadow: 0px 0px 12px 0 #ccc;
                margin: 10px;
                border: transparent 2px solid;
                font-size: 25px;
                font-weight: 500;
                box-shadow: 0px 1px 13px 0 rgba(111, 143, 195, 0.5);
                width: 100%;
                color: #a3a3a3;
  
                &.action {
                  border: #4493fa 2px solid;
                  color: #4493fa;
                }
              }
            }
          }
  
          .btn-box {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 64px 0 400px 0;
  
            .van-button {
              width: 47%;
            }
          }
        }
  
        //第三步
        .success-box {
          text-align: center;
          padding: 20px 0 170px 0;
  
          & > img {
            width: 408px;
            height: 408px;
            margin: 10px 0 36px;
          }
  
          & > div {
            img {
              width: 307px;
              height: 307px;
              pointer-events: auto;
            }
          }
  
          & > span {
            display: block;
            color: #a5a5a5;
            font-size: 28px;
            margin-top: 24px;
          }
          p {
            position: fixed;
            bottom: 70px;
            width: 100%;
            text-align: center;
            button {
              width: 80%;
            }
          }
        }
      }
    }
  </style>
  <style>
    .van-dialog.agreement .van-dialog__header {
      font-size: 24px;
      font-weight: bold;
    }
  
    .van-dialog.agreement .van-dialog__content {
      height: 50vh;
      overflow: auto;
      padding: 10px 20px;
      color: #716b6b;
    }
    .van-dialog.agreement .van-dialog__content div {
      font-size: 16px !important;
      /* text-indent: 2em; */
    }
  </style>
  