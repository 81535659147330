<template>
  <div class="home">
    <div class="container">
      <img src="@/assets/images/page1.jpg" />
      <img src="@/assets/images/page2.jpg" />
      <img src="@/assets/images/page3.jpg" />
      <img src="@/assets/images/page4.jpg" />
      <img src="@/assets/images/page5.jpg" />
      <img src="@/assets/images/page6.jpg" />
    </div>
    <div class="btn-box">
      <van-button size="large" color="linear-gradient(to right, #ff6b05, #ff4f5a)" @click="jumpHandler">{{ text }}</van-button>
      <van-button size="large" color="linear-gradient(to right, #01c2ce, #2f88fe)" @click="toActivation">增加权益人</van-button>
    </div>
  </div>
</template>

<script>
  import { verifyToken, loginJump } from '@/api';
  export default {
    name: 'homePage',
    data() {
      return {
        text: '',
      };
    },
    created() {
      // const isActivation = localStorage.getItem('isActivation');
      // this.text = isActivation === 'true' ? '使用健康卡' : '激活健康卡';
      this.init();
    },
    methods: {
      init() {
        let text = ['激活健康卡', '使用健康卡'];
        // 预先赋值默认文本(这样会闪一下,不管它)
        this.text = text[0];
        // 取token
        const token = localStorage.getItem('token');
        if (token?.length > 0) {
          loginJump({ token }).then((res) => {
            if(res.status === 1 && res.data.isJump === 1) {
              this.text = text[1];
            }
            // console.log('成功: ', res);
          })
        }
      },
      // 去激活页面
      toActivation(){
        const token = localStorage.getItem('token');
        if (token?.length > 0) {
          loginJump({ token })
            .then((res) => {
              if(res.status === 0) {
                // 跳转到登录页面
                localStorage.removeItem('token');
                this.$router.push({ name: 'loginPage' });
              } else {
                // 去激活页面
                this.$router.push({ name: 'activationPage' });
              }
            })
            .catch((error) => {
              this.$dialog.alert({
                message: error.message,
              });
            });
        } else {
          console.log('跳转到登录页面');
          // 跳转到登录页面
          this.$router.push({ name: 'loginPage' });
        }
      },
      jumpHandler() {
        const token = localStorage.getItem('token');
        if (token?.length > 0) {
          loginJump({ token })
            .then((res) => {
              console.log(res)
              if(res.status === 0) {
                // 去登录页面
                localStorage.removeItem('token');
                this.$router.push({ name: 'loginPage' });
              } else if(res.status === 1 && res.data.isJump === 1) {
                // 跳转到指定页面
                window.location.href = res.data.jumpUrl;
              } else {
                // 去激活页面
                this.$router.push({ name: 'activationPage' });
              }
            })
            .catch((error) => {
              this.$dialog.alert({
                message: error.message,
              });
            });
        } else {
          console.log('跳转到登录页面');
          // 跳转到登录页面
          this.$router.push({ name: 'loginPage' });
        }
      },
    },
  };
</script>
<style scoped lang="scss">
  .container {
    font-size: 0;
    padding-bottom: 144px;
    background: #ecf4ff;
    & > img {
      width: 100%;
    }
  }
  .btn-box {
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    bottom: 70px;
    width: 100%;
  }
  .van-button {
    width: 40%;
    border-radius: 50px;
    height: 56px;
    font-size: 30px;
  }
</style>
