import axios from "axios";
let baseURL = process.env.VUE_APP_URL;
const request = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

request.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default request;
