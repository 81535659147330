import request from "./request";

export function verifyToken (params) {
  return request({
    url: '/verify-token',
    method: 'get',
    params
  })
}
export function getPhoneCode (params) {
  return request({
    url: '/get-phone-code',
    method: 'get',
    params
  })
}
export function verifyPhoneCode (data) {
  return request({
    url: '/verify-phone-code',
    method: 'POST',
    data
  })
}
export function addEquity (data) {
  return request({
    url: '/add-equity',
    method: 'POST',
    data
  })
}
export function getServiceQr (params) {
  return request({
    url: '/get-service-qr',
    method: 'get',
    params
  })
}
export function loginJump (params) {
  return request({
    url: '/login-jump',
    method: 'get',
    params
  })
}